import React from "react"
import { graphql } from "gatsby"
import SiteLayout from "../../../components/sitelayout"
import Header from "../../../components/header"
import SEO from "../../../components/seo"
import Container from "../../../components/container"
import Section from "../../../components/section"
import Segment, { SegmentTitle, SegmentSubTitle, SegmentCategory, SegmentText } from "../../../components/segment"
import ExternalLink from "../../../components/externallink"
import Screenshot from "../../../components/screenshot"
import SubText from "../../../components/subtext"
import SupportCta from "../../../components/supportcta"
import MenuItem from "../../../components/menuitem"
import HelpLink from "../../../components/helplink"
import Highlighter from "../../../components/highlighter"
import Vimeo from "../../../components/vimeo"

import { useTheme } from "../../../services/theme"

const HubspotWebflowFormToolGuide = ({data}) => {

    const theme = useTheme()
    return (
        <SiteLayout
            menu={
                <MenuItem to="/help">Help Center</MenuItem>
            }
        >
            <SEO title="Guide - Connecting Webflow forms to Hubspot with Vimkit"/>
            <Container>
                <Header
                    paddingTop="10vh"
                    paddingBottom="20px"

                    title="Connecting Webflow forms to Hubspot"
                    subTitle="A step-by-step tutorial to connect Webflow forms to Hubspot using Vimkit's free tool."
                />
                <Vimeo id="441030489" allowfullscreen={true} title="Vimkit Overview" maxWidth="1280px" marginLeft="auto" marginRight="auto"/>

            </Container>
            <Section 
                paddingLeft="15%" 
                paddingRight="15%"
                paddingBottom="5%"
                breakpoint={theme.breakpoints.mobile}
                responsiveStyle="padding-left: 20px; padding-right: 20px;"
            >
                <Segment
                    header={
                        <SegmentTitle>
                            Prerequisites
                        </SegmentTitle>
                    }
                    headerWidth="25%"
                    contentWidth="75%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>1. A Webflow site</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="20px">
                        If you don't already have one, create a website on Webflow.
                         To use Vimkit you'll need a paid Webflow account or a site plan.
                         Use this&nbsp; 
                        <ExternalLink display="inline" to="https://university.webflow.com/article/intro-to-forms" fontWeight="600">
                            Webflow tutorial
                        </ExternalLink> if you need help creating a webpage with a form.
                         Style the form to your liking using Webflow's designer.
                    </SegmentText>
                    <SegmentSubTitle>2. A Hubspot form</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="20px">
                        Use your Hubspot account (or signup for a&nbsp;
                         <ExternalLink display="inline" to="https://www.hubspot.com/pricing/crm" fontWeight="600">free Hubspot account</ExternalLink>),
                         create a Hubspot form and add the fields you want to capture. Don't worry about styling the Hubspot form - we won't be using Hubspot's styling tools.
                         <br/><br/><Highlighter>Make sure the form field names on Hubspot match the form field names on Webflow.</Highlighter> 
                    </SegmentText>
                    <SegmentSubTitle>3. A Vimkit account</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="10px">
                        Create your <HelpLink display="inline" to="/tools/hubspot-webflow-form-tool" fontWeight="600">free Vimkit account</HelpLink> if you haven't already done so.
                    </SegmentText>
                    
                </Segment>
                <Segment
                    header={
                        <SegmentTitle paddingRight="20px">
                            <SegmentCategory color={theme.colors.extraSubtleText} marginBottom="10px">Step 1</SegmentCategory>
                            Add Vimkit to your Webflow site
                        </SegmentTitle>
                    }
                    headerWidth="25%"
                    contentWidth="75%"
                    paddingTop="40px"
                    id="addwebsite"
                >
                    <SegmentSubTitle>First, add a website on Vimkit.</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="10px">
                        &#8594;Navigate to the websites page and click 'Add Website'.
                        <SubText align="left">Login to your Vimkit account if you have not!</SubText>
                        <br/>
                        &#8594;Enter your website's domain and click 'Add Website'. 
                        <SubText align="left">Don't include folders or full page paths.
                         Just the domain (or subdomain) is enough.
                         Choose the stage if you have multiple environments for testing or development.
                         If you have only one environment, just choose 'Production'.</SubText>
                    </SegmentText>
                    <Screenshot 
                        fluid={data.addWebsiteScreenshot.childImageSharp.fluid} 
                        minWidth="340px" 
                        marginBottom="20px"
                        title="Add a website"
                        alt="Add website screenshot"
                    />
                    <SegmentSubTitle id="installcode">Copy and paste the Vimkit code snippet on your Webflow site.</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="20px">
                        &#8594;Copy the code snippet. 
                        <br/><br/>
                        &#8594;Paste the code snippet in the Head Code section of your Webflow site (see 
                        <ExternalLink 
                            display="inline"
                            fontWeight="600"
                            to="https://university.webflow.com/article/how-to-add-custom-head-and-body-code-to-a-webflow-site#custom-code-in-your-project-settings">
                             &nbsp;Webflow's documentation
                        </ExternalLink> for details).
                    </SegmentText>
                    <Screenshot fluid={data.installCodeScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="20px"/>
                    <SegmentSubTitle>Publish your Webflow site and check if the code snippet was installed correctly.</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="20px">
                        &#8594;Click publish on your Webflow site (see 
                        <ExternalLink 
                            display="inline" 
                            fontWeight="600"
                            to="https://university.webflow.com/article/publish-your-project#publish-the-whole-project"
                        >
                         &nbsp;Webflow's documentation
                        </ExternalLink> for details). 
                        <SubText align="left">If you host your site somewhere else, export the code from Webflow
                         and follow your standard process to publish your website.</SubText><br/>
                         &#8594;Once your website has been published, click 'Verify' on Vimkit.
                         You'll see a confirmation if the code snippet has been installed correctly.
                    </SegmentText>
                </Segment>
                <Segment
                    header={
                        <SegmentTitle paddingRight="20px" marginBottom="10px">
                            <SegmentCategory color={theme.colors.extraSubtleText}>Step 2</SegmentCategory>
                            Setup your Hubspot form
                        </SegmentTitle>
                    }
                    headerWidth="25%"
                    contentWidth="75%"
                    paddingTop="40px"
                    id="setupform"
                >
                    <SegmentSubTitle>Next, connect your Hubspot account and choose the form you want to use</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="10px">
                        &#8594;Click 'Connect Hubspot Account'.
                        <SubText align="left"> You will be prompted to sign into your Hubspot account if you are not signed in.</SubText>
                    </SegmentText>
                    <Screenshot fluid={data.connectHubspotScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="20px"/>
                    <SegmentText marginTop="0px" marginBottom="10px">
                        &#8594;Click 'Grant Access' to give Vimkit access to your Hubspot forms. 
                        <SubText align="left">You must be a Super Admin on the Hubspot account to grant access to Vimkit.
                         If you don't have Super Admin access or have trouble connecting the Hubspot account to Vimkit,
                         you can use the form's embed code instead (see next section).</SubText>
                    </SegmentText>
                    <Screenshot fluid={data.grantHubspotScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="20px"/>
                    <SegmentText marginTop="0px" marginBottom="20px">
                        &#8594;Vimkit will automatically list all the forms in your Hubspot account. Choose the one that you'd like to use.
                    </SegmentText>
                    <Screenshot fluid={data.addFormScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="20px"/>
                    <SegmentSubTitle id="addformmanual"><Highlighter>If you cannot connect your Hubspot account, use the Hubspot form's embed code instead.</Highlighter></SegmentSubTitle>
                    <SegmentText marginTop="10px" marginBottom="20px">
                        &#8594;Copy the form's embed code from Hubspot
                         (see 
                        <ExternalLink 
                            fontWeight="600"
                            to="https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#add-the-form-embed-code"
                            display="inline"
                        >
                            &nbsp;Hubspot's documentation
                        </ExternalLink> for details).<br/><br/>
                         &#8594;Paste the embed code and click 'Add Form'.
                    </SegmentText>
                    <Screenshot fluid={data.addFormManualScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="20px"/>
                    <SegmentSubTitle id="customattribute">Add the custom attribute to the form on Webflow (you're almost there!)</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="20px">                        
                        &#8594;Go to Webflow, select the form element and add a custom attribute. See 
                        <ExternalLink 
                            fontWeight="600"
                            to="https://university.webflow.com/article/how-to-add-custom-attributes-to-an-element-in-webflow#custom-attributes"
                            display="inline"
                        >
                            &nbsp;Webflow's documentation
                        </ExternalLink> for details.
                        <SubText align="left">Make sure you've selected the 'Form' element - not the Form block element.</SubText><br/>
                        &#8594;Copy and paste the the custom attribute name and value from Vimkit on Webflow and click save.  
                    </SegmentText>
                    <Screenshot fluid={data.customAttributeScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="20px"/>
                    <SegmentSubTitle>Finally, publish your Webflow site and check if the data attribute was added correctly.</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="20px">
                        &#8594;Click publish on your Webflow site (see 
                        <ExternalLink 
                            display="inline"
                            fontWeight="600" 
                            to="https://university.webflow.com/article/publish-your-project#publish-the-whole-project"
                        >
                         &nbsp;Webflow's documentation
                        </ExternalLink> for details). 
                        <SubText align="left">If you host your site somewhere else, export the code from Webflow
                         and follow your standard process to publish your website.</SubText><br/>
                        &#8594;Enter the full URL of the page where you are using the form and click 'Verify' on Vimkit.
                         You'll see a confirmation if the custom attribute has been added correctly.
                    </SegmentText>
                    <Screenshot fluid={data.verifyFormScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="20px"/>
                </Segment>
                <Segment
                    header={
                        <SegmentTitle paddingRight="20px" marginBottom="10px">
                            <SegmentCategory color={theme.colors.extraSubtleText}>Step 3</SegmentCategory>
                            Test it and you're all set!
                        </SegmentTitle>
                    }
                    headerWidth="25%"
                    contentWidth="75%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Great work! Let's try using the form.</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="20px">
                        &#8594;Open your website, fill out the form and submit it.<br/><br/>

                        If there are no validation errors, Vimkit will send the submission to your Hubspot form. 
                        <SubText align="left">Vimkit will validate the values submitted in real-time using Hubspot's API and show any validation errors
                         (for example, an email domain that doesn't exist).</SubText> 
                    </SegmentText>
                    <SegmentSubTitle>See the form submission on Hubspot.</SegmentSubTitle>
                    <SegmentText marginTop="0px">
                        &#8594;Head over to Hubspot, to see your form submission (see 
                        <ExternalLink 
                            fontWeight="600"
                            to="https://knowledge.hubspot.com/forms/analyze-form-submissions-data#manage" 
                            display="inline"
                        >
                            &nbsp;Hubspot's documentation
                        </ExternalLink> for details).
                    </SegmentText>
                </Segment>
            </Section>
            
            <SupportCta/>
        </SiteLayout>    
    )
}

export default HubspotWebflowFormToolGuide

export const query = graphql`
    query {
        addWebsiteScreenshot: file(relativePath: { eq: "screenshots/addwebsite.png" }) {
            childImageSharp {
                fluid(maxWidth: 960, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
        installCodeScreenshot: file(relativePath: { eq: "screenshots/installcode.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
        connectHubspotScreenshot: file(relativePath: { eq: "screenshots/connecthubspot.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
        grantHubspotScreenshot: file(relativePath: { eq: "screenshots/granthubspot.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
        addFormScreenshot: file(relativePath: { eq: "screenshots/addform.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
        addFormManualScreenshot: file(relativePath: { eq: "screenshots/addformmanual.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
        customAttributeScreenshot: file(relativePath: { eq: "screenshots/customattribute.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
        verifyFormScreenshot: file(relativePath: { eq: "screenshots/verifyform.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
    }
`